<template>
  <section class="section">
    <div v-if="!emailVerified.success" class="container">
      <div class="columns head-column">
        <div class="column is-half is-offset-one-quarter">
          <h1>Bestätigung der E-Mailadresse wird geprüft</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <progress class="progress is-large is-info" max="100"></progress>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <p>einen Moment bitte...</p>
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <p>Hash: {{hash}}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <button @click="confirmEmail">confirm</button>
        </div>
      </div>-->
    </div>
    <div v-if="emailVerified.success" class="container">
      <div class="columns head-column">
        <div class="column is-half is-offset-one-quarter">
          <h1>Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse.</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <h2>Nächster Schritt:</h2>
          <p>
            Melden Sie sich jetzt direkt in Ihrem
            <router-link to="/account">Nutzerkonto an</router-link>.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "verifyEmail",
  data() {
    return {
      hash: this.$route.params.hash
    };
  },
  created: function() {
    // this.$store.dispatch("emailValidation", this.$route.params.hash);
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      if (this.$route.params.hash) {
        this.$store.dispatch("emailValidation", this.$route.params.hash);
      }
    }
  },
  computed: {
    ...mapGetters(["emailVerified"])
  }
};
</script>